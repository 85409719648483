<template>
  <div>
    <CRow>
      <CCol sm="12">
        <TypesTable
          :items="types"
          hover
          striped
          border
          small
          fixed
          caption="Tipos"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import TypesTable from '../../components/types/TypesTable.vue'
import types from '../../services/types';
import store from '../../store'

export default {
  name: 'Types',
  components: { TypesTable },
  data: function () {
		return {
            types: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await types.get();

    if(response.type == "success"){
      this.types = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.types = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
